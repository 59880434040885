<template>
  <div>
    <b-card-code title="معلومات الحالة ">
      <template v-if="serviceTracking.length > 0">
        <b-row v-for="(service, index) in serviceTracking" :key="index">
          <b-col md="6" xl="4">
            <b-form-group label-for="date">
              <label>تاريخ التواصل</label>
              <h5 class="text-capitalize mb-75">
                {{ service.track_date }}
              </h5>
            </b-form-group>
          </b-col>
          <b-col md="12" xl="4">
            <b-form-group label-for="notes">
              <label>الملاحظات</label>
           
                <div v-html="service.track_notes"></div>

            
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <label>لا يوجد معلومات </label>
      </template>
      <br/>
      <br/>
      <br/>
      <b-row>
        <b-col class="border-Primary" xl="2" v-if="serviceReports.length ==0">
          <b-button variant="purple" @click="addReport">
            <span class="align-middle"> إضافة تقرير جديد</span>
          </b-button>
        </b-col>
         <br/>
           <br/>
        <b-col xl="4"></b-col>
        <b-col class="border-Primary" xl="4">
          <b-button variant="purple" @click="CloseStatus">
            <span class="align-middle"> إغلاق الحالة</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </b-card-code>
    <serviceReport
      v-for="(serviceReport, index) in serviceReports"
      :key="index"
      @click="addReport"
      @getServicTrackings="getServicTrackings"
    >
    </serviceReport>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormRating,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import serviceReport from "./service-report.vue";
export default {
  components: {
    BCardCode,
    BFormRating,
    BCardText,
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    ToastificationContent,
    BButton,
    serviceReport,
  },

  data() {
    return {
      role:[],
      includesCoordinator:false,
      value: null,
      value2: 2.67,
      serviceReports: [],
      serviceTracking: [
       
      ],
    };
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.roles
    this.includesCoordinator = this.role.some(role => role.name === "coordinator");
    this.getServicTrackings();
  },

  methods: {
    addReport() {
      this.serviceReports.push(serviceReport);
    },
    getServicTrackings() {
      let url = `/api/v1/service_management/${this.$route.params.id}/trackings`;
      this.$http.get(url).then((res) => {
        //console.log(res.data.data);
        this.serviceTracking = res.data.data;
           //console.log(this.serviceTracking);
      });
    },
    
    CloseStatus() {
          this.$swal({
 
        text: "هل تريد بالتأكيد إغلاق الحالة ؟",
        icon: 'warning',
     
        confirmButtonText: 'موافق',
           allowEscapeKey: false,
   
      allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let url = `/api/v1/service_management/${this.$route.params.id}/close`;
      this.$http.put(url).then(() => {
        if(this.includesCoordinator)
          this.$swal({
               title: "تم  إغلاق  الحالة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      else 
      this.$swal({
               title: "تم طلب  إغلاق  الحالة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
            
        }
        
      })
     
    },
  },
};
</script>

<style scoped>
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
</style>